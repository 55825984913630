import _upperFirst from 'lodash/upperFirst';
import 'lodash/isObject';
import { getIn } from 'immutable';
import 'invariant';

/*
 *
 * Error: `ApiMaxResponseTimeExceededError`.
 *
 */ // Create an `Error` to be fired when an `API` call to the back end does not return
// in the expected time (in milliseconds). This can happen when there is heavy
// load on the server or the client has a very slow network connection.
class ApiMaxResponseTimeExceededError extends Error{static makeApiIdentifier(a){let{fetchOptions:b,requestURL:c}=a;const{apiId:d}=b;return ""+(d?"API: `"+d+"`":"")+(c?"URL: `"+c+"`":"")+(d||c?"":"an unknown API (not passed to this error)")}static computeErrorMessage(a){void 0===a&&(a={});const{fetchOptions:b}=a,{maxResponseTimeMs:c}=b,d=![null,void 0].includes(c);let e="The API call to "+ApiMaxResponseTimeExceededError.makeApiIdentifier(a)+" did not return in "+(d?c+"ms.":"the expected time (timeout unknown, not passed to this error)");b&&(e=e+" Call options: `"+JSON.stringify(b)+"`.");return e+=" DBH_VERSION_CODE: `2024-08-26T19_12_53-a78e5384a0f0f0f47cfc3e5fc13ba6f9efe70e19-production-bitbucket-8220`.",e}constructor(a){const{requestAttributes:b,timings:c,fetchOptions:d,responseHeaders:e}=a,f=ApiMaxResponseTimeExceededError.computeErrorMessage(a);super(f),this.requestAttributes=b,this.timings=c,this.fetchOptions=d,this.responseHeaders=e;}}ApiMaxResponseTimeExceededError.getClassName=()=>"ApiMaxResponseTimeExceededError";var ApiMaxResponseTimeExceededError$1 = ApiMaxResponseTimeExceededError;

/**
 * Adds final dot to the given text if it doesn't exist.
 * @param {string?} text .
 * @return {string} The text with guaranteed dot at the end.
 */const makeTextWithFinalDot=a=>{if(!a)return a;const b=a.trim();return b.endsWith(".")?b:b+"."};// To be fired when the `API` returns an error response.
class ApiRequestError extends Error{constructor(a){var b;const{apiResponse:c,requestAttributes:d,fetchOptions:e,responseHeaders:f,statusCode:g,timings:h,emptyResponse:i}=a,{message:j}=ApiRequestError.generateErrorMessages(a);super(j),this.name=ApiRequestError.getClassName(),this.apiResponse=c,this.statusCode=g,this.emptyResponse=i,this.apiResponseErrors=getIn(c,["errors"]),this.requestAttributes=d,this.fetchOptions=e,this.timings=h,this.dryRun=!(!(null!=d)||!d.get("dryRun")),this.tokenIsInvalid=!(!(null!=(b=this.apiResponseErrors))||!b.some(a=>a&&"token"===a.field)),this.responseHeaders=f;}isTokenInvalid(){return this.tokenIsInvalid}shouldSendToSentry(){return !this.dryRun}}ApiRequestError.getClassName=()=>"ApiRequestError",ApiRequestError.generateErrorMessages=function(a){void 0===a&&(a={});const{emptyResponse:b,apiResponse:c,requestAttributes:d,statusCode:e}=a;let f="`API` error";const g=getIn(c,["hydra:description"]),h=getIn(c,["errors"]),i=getIn(d,["request","url"]),j=getIn(d,["request","method"]),k=getIn(d,["request","options","body"]),l=getIn(d,["request","options","dryRun"]);f=f+" "+(b?"[EMPTY RESPONSE]":"")+" ["+j+"]["+e+"] "+i+(k?" [BODY] "+JSON.stringify(k):"")+(g?" [DESC] "+makeTextWithFinalDot(g):"")+(l?" (dry run)":"");let m,n;try{n=d&&JSON.stringify(d),m=h&&JSON.stringify(h);}catch(a){// Ignore the failure: this is only for logging purposes.
}if(m){const a=h.reduce((a,b)=>{let c=a;const{message:d,field:e}=b;return c+="`"+e+"`: "+makeTextWithFinalDot(_upperFirst(d))+" ",c},"").trim();a.length&&(f+=" [ERRORS] "+a);}return n&&(f+=" [ALL REQUEST INFO] "+n),{message:f}};var ApiRequestError$1 = ApiRequestError;

// or our server side rendering is having a network problem (or disconnection).
class ApiRequestFailedBecauseOfNetworkError extends Error{constructor(a){const{requestAttributes:b,timings:c,fetchOptions:d,responseHeaders:e,fetchError:f}=a;const{message:g}=f,h="The `API` call failed because of network disconnected or internal `API` 500 error: `"+g+"`\n"+("`"+JSON.stringify(b)+"`.");super(h),this.name=ApiRequestFailedBecauseOfNetworkError.getClassName(),this.requestAttributes=b,this.timings=c,this.fetchOptions=d,this.responseHeaders=e;}}ApiRequestFailedBecauseOfNetworkError.getClassName=()=>"ApiRequestFailedBecauseOfNetworkError";var ApiRequestFailedBecauseOfNetworkError$1 = ApiRequestFailedBecauseOfNetworkError;

export { ApiMaxResponseTimeExceededError$1 as ApiMaxResponseTimeExceededError, ApiRequestError$1 as ApiRequestError, ApiRequestFailedBecauseOfNetworkError$1 as ApiRequestFailedBecauseOfNetworkError };
