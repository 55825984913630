import '@dbh/throw-in-server-side-rendering';
import _noop from 'lodash/noop';
import 'prop-types';

/*
 *
 * Constants: `@dbh/tracking-gtag-shared-with-browser`.
 *
 */const COOKIES_PREFIX={GA4:"ga4"};const NOT_SUPPORTED="not supported";

/**
 * Extracts telemetric data from `window.navigator` `API` about the connection.
 * @typedef {{navigatorConnectionEffectiveType: string, navigatorConnectionDownlink: string}} ConnectionData
 * @return {ConnectionData} .
 * - `navigatorConnectionEffectiveType`: type of connection based
 * on metrics, if the browser supports this `API`, a `null` value otherwise.
 * - `navigatorConnectionDownlink`: the effective bandwidth in `kbps`,
 * if the browser supports this `API`, a `null value` otherwise.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/downlink}
 */const extractConnectionDataFromNavigator=()=>{let a=NOT_SUPPORTED,b=NOT_SUPPORTED;return "connection"in navigator&&(a=navigator.connection.effectiveType,b=navigator.connection.downlink||NOT_SUPPORTED),{navigatorConnectionEffectiveType:a,navigatorConnectionDownlink:b}};var extractConnectionDataFromNavigator$1 = extractConnectionDataFromNavigator;

/*
 *
 * `ensureGtag`: `@dbh/gtag-shared-with-browser`.
 *
 */const ensureGtag=()=>{window.dataLayer=window.dataLayer||[],window.gtag=window.gtag||function(){// Do not use `arguments` in other cases, in this case we just want to avoid
// babel transpiling the code.
// eslint-disable-next-line prefer-rest-params
window.dataLayer.push(arguments);};};var ensureGtag$1 = ensureGtag;

const gtagDataPropType=_noop;var gtagDataPropType$1 = gtagDataPropType;

const gtagDataForFirstPageViewPropType=_noop;var gtagDataForFirstPageViewPropType$1 = gtagDataForFirstPageViewPropType;

export { COOKIES_PREFIX, NOT_SUPPORTED, ensureGtag$1 as ensureGtag, extractConnectionDataFromNavigator$1 as extractConnectionDataFromNavigator, gtagDataForFirstPageViewPropType$1 as gtagDataForFirstPageViewPropType, gtagDataPropType$1 as gtagDataPropType };
