import { API_IDS_MAX_RESPONSE_TIMES, frontEndOrBackEndApiIdPropType, FE_API_IDS } from '@dbh/api-constants';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { makeSelectFrontEndApiUrl, makeSelectFrontEndApiDomainCookieValue } from '@dbh/configuration-redux';
import { makeSelectIsLoggerEnabled } from '@dbh/logger-redux';
import { makeSelectReactRouterPageType } from '@dbh/routing-redux';
import { selectDataFromSsr } from '@dbh/ssr-data-redux';
import { makeSelectUserAccessToken } from '@dbh/user-area-redux';
import { makeSelectIsDaybreakHeroSite, makeSelectDaybreakHeroData } from '@dbh/daybreak-hero-redux';
import 'react-immutable-proptypes';
import { isServerSideRendering } from '@dbh/environment';
import { keyIn } from '@dbh/lodash-extra';

/**
 * Given an `API_ID` return the maximum "accepted" API response time (by the front
 * end). Useful when there is a problem with the API and it replies too slowly.
 * This is a "fake" selector factory, since it doesn't take values from the state,
 * but it may in the future (we may fetch such values from an URL, to be able
 * to modify them without rebuilding this app, and put them in the state).
 * @param {string} API_ID The "API ID".
 * @return {number} The maximum accepted API response time in milliseconds.
 */const makeSelectApiMaxResponseTime=withConformsTo("makeSelectApiMaxResponseTime",[],a=>(/* `state` */)=>API_IDS_MAX_RESPONSE_TIMES[a]||API_IDS_MAX_RESPONSE_TIMES.default);var makeSelectApiMaxResponseTime$1 = makeSelectApiMaxResponseTime;

/**
 * Makes `request` options from given data, adding some headers (i.e `referrer`)
 * that the browsers send automatically, but `SSR` doesn't. Used in sagas, where
 * we make all the `HTTP` requests (both in server and in the browsers).
 * @param {Object} options The source data.
 * @param {Immutable.Map} options.dataFromSsr Data related to the request: we get such
 * data in server side rendering, from the request `Object`of `express.js`.
 * @param {string?} options.userToken The authentication token of the user, if
 * the user is signed in.
 * @param {number?} options.maxResponseTimeMs The maximum response time that
 * we accept for the request.
 * @param {boolean} options.dryRun .
 * @param {string?} options.apiCallsLogsUrl .
 * @param {string?} options.apiEnv .
 * @param {string?} options.frontEndApiEnvCookieValue .
 * @param {string?} options.frontEndApiDomainCookieValue .
 * @param {string?} options.apiId .
 * @return {Object} The request options, with headers useful for debugging.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch}
 */const makeRequestOptions=withConformsTo("makeRequestOptions",[],a=>{var b,c;const{dataFromSsr:d,userToken:e,maxResponseTimeMs:f,dryRun:o=!1,isContentLengthCalculationEnabled:g,headers:h,apiCallsLogsUrl:i,apiId:j,env:k,frontEndApiEnvCookieValue:l,frontEndApiDomainCookieValue:m,pageType:n}=a;let p,q={};if(d){p=d.get("httpRequestToServerSideRendering");const a=d.getIn(["queryFromServer","utm_source"]);q={"X-Forwarded-Host":d.getIn(["httpRequestToServerSideRendering","hostname"]),...(a?{"X-Utm-Source":a}:{})};}const r=isServerSideRendering()?(null==(b=p)||null==(c=b.get("cfGeolocationDataHeadersForSsrRequests"))?void 0:c.toObject())||{}:{},s={url:null==d?void 0:d.get("locationHrefFromServer"),templateCode:n},t={...(d?{...d.filter(keyIn("anonymousId","guidSession","guidTab")).toObject(),userAgent:p.get("userAgent"),isBot:p.get("isBot"),...(isServerSideRendering()?{referrer:d.get("locationHrefFromServer"),...r}:{})}:{}),...(e?{Authorization:"Bearer "+e}:{}),...h};return {dbhHeaders:t,headers:{...q,"x-is-dry-run":o},maxResponseTimeMs:f,dryRun:o,isContentLengthCalculationEnabled:g,apiCallsLogsUrl:i,apiId:j,env:k,frontEndApiEnvCookieValue:l,frontEndApiDomainCookieValue:m,websitePage:s}});var makeRequestOptions$1 = makeRequestOptions;

/**
 * Creates "request options for `request`, to be used in `sagas`.
 * @param {string} API_ID The ID of an `API`.
 * @param {Object?} options .
 * @param {boolean} options.dryRun .
 * @param {boolean} options.forceNoAuthentication To ignore adding `accessToken` to `headers`.
 * @return {Object} The request options to be passed to `request`.
 * @see `@dbh/request`.
 */const makeSelectSagaRequestOptions=withConformsTo("makeSelectSagaRequestOptions",[["API_ID",frontEndOrBackEndApiIdPropType.isRequired],["options",PropTypes.object]],(a,b)=>{const{forceNoAuthentication:c,dryRun:d}=b||{};return createSelector(selectDataFromSsr,makeSelectApiMaxResponseTime$1(a),makeSelectUserAccessToken(),makeSelectIsLoggerEnabled(),makeSelectIsDaybreakHeroSite(),makeSelectDaybreakHeroData(),makeSelectFrontEndApiUrl(FE_API_IDS.apiCallsLogs),makeSelectFrontEndApiDomainCookieValue(),makeSelectReactRouterPageType(),(b,e,f,g,h,i,j,k,l)=>{let[m,n,o]=j;return makeRequestOptions$1({dataFromSsr:b,userToken:c?void 0:f,maxResponseTimeMs:e,dryRun:d,isContentLengthCalculationEnabled:g,apiCallsLogsUrl:m,apiId:a,env:n,frontEndApiEnvCookieValue:o,frontEndApiDomainCookieValue:k,pageType:l,headers:h?{"partner-id":i.get("partnerId"),"partner-site":i.get("subDomain")}:void 0})})});var makeSelectSagaRequestOptions$1 = makeSelectSagaRequestOptions;

export { makeSelectApiMaxResponseTime$1 as makeSelectApiMaxResponseTime, makeSelectSagaRequestOptions$1 as makeSelectSagaRequestOptions };
